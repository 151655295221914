<template>
  <validation-observer ref="validationObserver">
    <b-card-form title="Section Title">
      <div>
        <b-row>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <b-form-group
              label="Title English"
              label-for="titleEn"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Title English"
              >
                <b-form-input
                  id="titleEn"
                  v-model="data.titleEn"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Title English"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
            dir="rtl"
          >
            <b-form-group
              label="Title Arabic"
              label-for="titleAr"
            >

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Title Arabic"
              >
                <b-form-input
                  id="titleAr"
                  v-model="data.titleAr"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Title Arabic"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <b-form-group
              label="Subtitle English"
              label-for="subtitleEn"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Subtitle English"
              >
                <b-form-input
                  id="subtitleEn"
                  v-model="data.subTitleEn"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Subtitle English"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
            dir="rtl"
          >
            <b-form-group
              label="Subtitle Arabic"
              label-for="subtitleAr"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Subtitle Arabic"
              >
                <b-form-input
                  id="subtitleAr"
                  v-model="data.subTitleAr"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Subtitle Arabic"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="12"
            class="mb-1"
          >
            <label for="wildcard">Title Image</label>
            <b-form-file
              id="wildcard"
              ref="file"
              v-model="imageTitle"
              type="file"
              accept="image/*"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="11"
            class="mb-1"
          >
            <b-img
              fluid
              :src="data.mainPhotoUrl"
              alt="Card image cap"
            />
          </b-col>
        </b-row>
      </div>
    </b-card-form>
    <b-card-form title="Steps">
      <div>
        <div>
          <b-form
            ref="form"
            :style="{ height: trHeight }"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >
            <!-- Row Loop -->
            <b-row
              v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-col cols="12">
                <b-row>
                  <!-- Item Name -->
                  <b-col
                    md="4"
                    xl="5"
                  >
                    <b-form-group
                      label="Title English"
                      label-for="titleEn"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :vid="'titleEn' + item.id"
                        rules="required"
                        name="Title English"
                      >
                        <b-form-input
                          id="titleEn"
                          v-model="item.titleEn"
                          type="text"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Title English"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Item Name -->
                  <b-col
                    md="4"
                    xl="5"
                    dir="rtl"
                  >
                    <b-form-group
                      label="Title Arabic"
                      label-for="titleAr"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :vid="'titleAr' + item.id"
                        rules="required"
                        name="Title Arabic"
                      >
                        <b-form-input
                          id="titleAr"
                          v-model="item.titleAr"
                          type="text"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Title Arabic"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeItem(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>Delete</span>
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="4"
                    xl="5"
                  >
                    <label for="textarea-default">Description English</label>
                    <validation-provider
                      #default="{ errors }"
                      :vid="'descriptionEn' + item.id"
                      rules="required"
                      name="Description English"
                    >
                      <b-form-textarea
                        id="textarea-default"
                        v-model="item.descriptionEn"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Description English"
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                  <!-- Item Name -->
                  <b-col
                    md="4"
                    xl="5"
                    dir="rtl"
                  >
                    <label for="textarea-default">Description Arabic</label>
                    <validation-provider
                      #default="{ errors }"
                      :vid="'descriptionAr' + item.id"
                      rules="required"
                      name="Description Arabic"
                    >
                      <b-form-textarea
                        id="textarea-default"
                        v-model="item.descriptionAr"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Description Arabic"
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="6"
                    xl="10"
                    class="mb-1"
                  >
                    <label for="wildcard">Member Photo</label>
                    <b-form-file
                      ref="file"
                      v-model="image"
                      type="file"
                      accept="image/*"
                      @input="upload(image, item)"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="6"
                    xl="11"
                    class="mb-1"
                  >
                    <b-img
                      fluid
                      :src="item.photoUrl"
                      alt="Card image cap"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </b-form>
        </div>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeateAgain"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Add New</span>
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="save(false)"
            >
              <span>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card-form>
  </validation-observer>
</template>

<script>
import BCardForm from '@/components/b-card-form'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormFile,
  BImg,
  BFormTextarea,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import base64Encode from '@/libs/UploadFilesService'
import UUID from '@/libs/uuid'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCardForm,
    BImg,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [],
      nextTodoId: 1,
      image: null,
      imageTitle: null,
      required,
      data: {},
    }
  },
  watch: {
    imageTitle(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.data.mainPhotoUrl = URL.createObjectURL(newValue)

          this.progress = 0
          base64Encode(newValue)
            .then(value => {
              const config = { headers: { 'Content-Type': 'application/json' } }
              this.$http.post('/uploads', JSON.stringify(value), config)
                .then(response => {
                  this.data.mainPhotoUrl = response.data
                })
            })
        }
      }
    },
  },
  mounted() {
    Promise.all([
      this.$http.get('/WhyWorkWithUs'),
      this.$http.get('/WhyWorkWithUs/sections'),
    ]).then(values => {
      this.data = values[0].data
      this.items = values[1].data
    })
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: UUID(),
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    save(isPublish) {
      const valid = this.$refs.validationObserver
        .validate()
        .then(success =>  {
          if (success) {
            const data = { sections: this.items }
            Promise.all([
              this.$http.post('/WhyWorkWithUs/sections', data),
              this.$http.put('/WhyWorkWithUs', this.data)]).then(()=>{
                if(!isPublish)
                {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Save Successfully",
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    },
                  });
                }
              })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Check the feilds validations above to be able to save!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
          return success
        })
      return valid
    },
    upload(image, item) {
      const index = this.items.findIndex(element => element.id === item.id)
      this.items[index].photoUrl = URL.createObjectURL(image)

      this.progress = 0
      base64Encode(image).then(value => {
        const config = { headers: { 'Content-Type': 'application/json' } }
        this.$http
          .post('/uploads', JSON.stringify(value), config)
          .then(response => {
            this.items[index].photoUrl = response.data
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
