<template>
  <b-tabs>
    <b-tab active>
      <template #title>
        <feather-icon icon="HomeIcon" />
        <span>Main Section</span>
      </template>

      <work-with-us-main ref="main" />
    </b-tab>
    <!-- New Tab Button (Using tabs-end slot) -->
    <template #tabs-end>
      <div class="ml-auto">
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              right
              @click.prevent="preview"
            >
              Preview
            </b-button>
          </b-col>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              right
              @click.prevent="publish"
            >
              Publish
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab, BButton, BCol, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import WorkWithUsMain from './WorkWithUsMain.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BCol,
    BRow,
    WorkWithUsMain,
  },
  directives: {
    Ripple,
  },
  methods: {
    preview() {
      Promise.all([
        this.$refs.main.save(true)]).then(values => {
        if (values.every(element => element === true)) {
          window.open(`${process.env.VUE_APP_PREVIEW_URL}work-with-us?preview=true`)
        }
      })
    },
    async publish() {
      try {
        await Promise.all([this.$refs.main.save(true)])
        await this.$http.post('/WhyWorkWithUs/publish')

        this.showToast('Published Successfully')
       
      } catch (error) {}
    },

    showToast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });
    }
  },
}
</script>
